import { useState, Fragment } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useConfirmDialog } from 'components/contexts/ConfirmContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import FileUploadDialog from 'web/screens/dialogs/files/FileUploadDialog';

import ErrorMessage from 'components/ErrorMessage';

import { GET_FILES } from 'api/queries/fileQueries';
import { DELETE_ONE_FILE } from 'api/mutations/fileMutations';

const DocumentsWrapper = styled.div`
	background-color: ${p => p.theme.palette.background.paper};
	margin-bottom: 20px;
	padding: 20px;
`;

const DocumentsTitle = styled.h3`
	margin: 0 0 20px 0;
`;

const NoDocumentsMessage = styled.div`
	color: ${p => p.theme.palette.text.secondary};
	font-style: italic;
	font-size: 0.9rem;
	font-weight: 300;
`;

export default function FileListWithUploadDialog({
	docId,
	uploadContext,
	canEdit = true,
}) {
	const {
		data: { files = [] } = { files: [] },
		loading: filesLoading,
		error: filesError,
	} = useQuery(GET_FILES, { variables: { docId: docId } });

	if (!canEdit && (!files || files?.length === 0)) {
		return null;
	}

	return (
		<DocumentsWrapper>
			<DocumentsTitle>Dokumenter</DocumentsTitle>

			<ErrorMessage errors={[filesError]} />

			{!filesLoading && (
				<div>
					{files && files.length > 0 ? (
						<List>
							{files.map(file => (
								<Fragment key={file._id}>
									<ListItem
										dense={true}
										divider={true}
										secondaryAction={
											canEdit && (
												<DocumentDeleteButton
													fileId={file._id}
													fileName={file.fileName}
													docId={docId}
												/>
											)
										}
									>
										<ListItemButton
											component="a"
											href={file.url}
											title={`Last ned ${file.fileName}`}
											target="_blank"
											rel="noreferrer"
										>
											<ListItemIcon>
												<AttachFileIcon fontSize="small" />
											</ListItemIcon>

											<ListItemText
												primary={file.fileName}
											/>
										</ListItemButton>
									</ListItem>
								</Fragment>
							))}
						</List>
					) : (
						<NoDocumentsMessage>
							Ingen dokumenter er lastet opp.
						</NoDocumentsMessage>
					)}

					{canEdit && (
						<DocumentsUploadButton
							docId={docId}
							uploadContext={uploadContext}
						/>
					)}
				</div>
			)}
		</DocumentsWrapper>
	);
}

function DocumentDeleteButton({ fileId, fileName, docId }) {
	const { verify } = useConfirmDialog();
	const { notify } = useSnackbar();

	const [deleteOneFile, { loading, error }] = useMutation(DELETE_ONE_FILE, {
		refetchQueries: [
			{
				query: GET_FILES,
				variables: { docId: docId },
			},
		],
	});

	return (
		<div>
			<ErrorMessage errors={[error]} />

			<IconButton
				edge="end"
				aria-label="Slett"
				disabled={loading}
				onClick={async () => {
					try {
						if (
							!(await verify({
								title: 'Slett fil',
								text: `Er du sikker på at du vil slette ${fileName}?`,
							}))
						) {
							return;
						}

						const { data } = await deleteOneFile({
							variables: {
								_id: fileId,
							},
						});

						if (data?.deleted) {
							notify(`${fileName} ble slettet!`);
						}
					} catch (err) {
						console.error(err);
					}
				}}
			>
				<DeleteIcon />
			</IconButton>
		</div>
	);
}

const UploadButtonWrapper = styled.div`
	margin-top: 20px;
	text-align: right;
`;

function DocumentsUploadButton({ docId, uploadContext }) {
	const [showDialog, setShowDialog] = useState(false);

	return (
		<div>
			<UploadButtonWrapper>
				<Button
					size="small"
					variant="contained"
					color="primary"
					type="button"
					startIcon={<CloudUploadIcon />}
					onClick={() => {
						setShowDialog(true);
					}}
				>
					Legg til dokument
				</Button>
			</UploadButtonWrapper>

			<FileUploadDialog
				docId={docId}
				uploadContext={uploadContext}
				open={showDialog}
				onClose={() => setShowDialog(false)}
			/>
		</div>
	);
}
