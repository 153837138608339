import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';

import { getRoleName } from 'server/context/roles';

import useSortOrder from 'hooks/useSortOrder';

import { useAppFilters } from 'web/contexts/AppFiltersContext';
import { useUser } from 'web/contexts/UserContext';

import UsersCreateDialog from 'web/screens/dialogs/users/UsersCreateDialog';

import ErrorAlert from 'components/ErrorAlert';
import { InlineLoading } from 'components/Loading';

import SearchFilter from 'components/filters/SearchFilter';
import AppFiltersActive from 'components/filters/AppFiltersActive';

import DesktopLayout, {
	ArchiveHeader,
	ArchiveHeaderRow,
} from 'web/components/Layout';
import ContentWithDrawer from 'web/components/ContentWithDrawer';
import SortIcon from 'web/components/SortIcon';
import Table, { TableRow, TableCell } from 'web/components/Table';
import Link from 'web/components/Link';
import CreateFabButton from 'web/components/CreateFabButton';
import LoadMoreButton from 'web/components/LoadMoreButton';

import CompaniesArchiveSidebar from 'web/components/companiesArchive/CompaniesArchiveSidebar';

import { PAGINATE_USERS } from 'api/queries/userQueries';

function isActiveUser(user) {
	if (!user) return false;

	return user.role === 'silent';
}

function isValidAddress(address) {
	if (!address) return false;

	return (
		address.locality &&
		address.postal_code &&
		address.street_address &&
		address.country
	);
}

const displayFilters = ['userRole'];

export default function UsersArchive() {
	const { userHasMinRole } = useUser();
	const navigate = useNavigate();
	const [{ order, orderBy }, setNewOrder] = useSortOrder('name');

	const { search, userRole } = useAppFilters();

	const [showUserCreateDialog, setShowUserCreateDialog] = useState(false);

	const {
		data: { users = { count: 0, items: [] } } = {
			users: { count: 0, items: [] },
		},
		error,
		loading,
		fetchMore,
	} = useQuery(PAGINATE_USERS, {
		variables: {
			order,
			orderBy,
			search,
			role: userRole?.value || null,
		},
	});

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label: 'Brukere',
				},
			]}
			title="Brukere"
		>
			<ContentWithDrawer
				drawer={
					<CompaniesArchiveSidebar displayFilters={displayFilters} />
				}
			>
				<div>
					<ArchiveHeader>
						<ArchiveHeaderRow>
							<SearchFilter label="Søk etter bruker" />
						</ArchiveHeaderRow>

						<AppFiltersActive displayFilters={displayFilters} />
					</ArchiveHeader>

					<ErrorAlert error={error} />

					<Table columns="8">
						<TableRow header>
							<TableCell
								start="1"
								end="7"
								onClick={() => setNewOrder('name')}
							>
								<span>Navn</span>

								<SortIcon
									field="name"
									orderBy={orderBy}
									order={order}
								/>
							</TableCell>

							<TableCell>Kontakt</TableCell>

							<TableCell onClick={() => setNewOrder('role')}>
								<span>Rolle</span>

								<SortIcon
									field="role"
									oerderBy={orderBy}
									order={order}
								/>
							</TableCell>
						</TableRow>

						{!loading && users?.items?.length === 0 && (
							<TableRow transparent>
								<TableCell start="1" end="-1" center>
									Ingen brukere ble funnet
								</TableCell>
							</TableRow>
						)}

						{users?.items.map(user => (
							<Link key={user._id} to={`/brukere/${user._id}/`}>
								<TableRow subdued={isActiveUser(user)} hover>
									<TableCell start="1" end="7">
										{user.name}
									</TableCell>

									<TableCell>
										<Tooltip
											title={
												user.phone
													? 'Har telefon'
													: 'Mangler telefon'
											}
											placement="top"
										>
											<PhoneIcon
												fontSize="small"
												color={
													user.phone
														? 'success'
														: 'error'
												}
											/>
										</Tooltip>

										<Tooltip
											title={
												user.email
													? 'Har epost'
													: 'Mangler epost'
											}
											placement="top"
										>
											<EmailIcon
												fontSize="small"
												color={
													user.email
														? 'success'
														: 'error'
												}
											/>
										</Tooltip>

										<Tooltip
											title={
												isValidAddress(user.address)
													? 'Har adresse'
													: 'Mangler adresse'
											}
											placement="top"
										>
											<HomeIcon
												fontSize="small"
												color={
													isValidAddress(user.address)
														? 'success'
														: 'error'
												}
											/>
										</Tooltip>

										{user.identificationFile && (
											<Tooltip
												title="Har manuelt lastet opp ID-dokument"
												placement="top"
											>
												<ContactEmergencyIcon
													fontSize="small"
													color="success"
												/>
											</Tooltip>
										)}
									</TableCell>

									<TableCell>
										{getRoleName(user.role)}
									</TableCell>
								</TableRow>
							</Link>
						))}

						{loading && (
							<TableRow transparent>
								<TableCell start="1" end="-1">
									<InlineLoading />
								</TableCell>
							</TableRow>
						)}

						{!loading && users?.items?.length !== 0 && (
							<TableRow footer>
								<TableCell start="1" end="-1" right>
									Viser {users.items.length} av {users.count}{' '}
									brukere
								</TableCell>
							</TableRow>
						)}
					</Table>

					{users && users.count > users.items.length && (
						<LoadMoreButton
							disabled={loading}
							onClick={() => {
								fetchMore({
									variables: {
										offset: users.items.length,
									},
									updateQuery: (
										prev,
										{ fetchMoreResult }
									) => {
										if (
											fetchMoreResult.users.items
												.length === 0
										) {
											return prev;
										}

										const result = {
											users: {
												...prev.users,
												items: [
													...prev.users.items,
													...fetchMoreResult.users
														.items,
												],
											},
										};

										return result;
									},
								});
							}}
						/>
					)}
				</div>
			</ContentWithDrawer>

			{userHasMinRole('accountant') && (
				<CreateFabButton
					onClick={() => setShowUserCreateDialog(true)}
				/>
			)}

			<UsersCreateDialog
				open={showUserCreateDialog}
				onClose={() => setShowUserCreateDialog(false)}
				onSubmitSuccess={user => {
					setShowUserCreateDialog(false);

					navigate(`/brukere/${user._id}/`);
				}}
			/>
		</DesktopLayout>
	);
}
