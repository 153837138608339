import { useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';

import { formatDate } from 'utils/dates';

import theme from 'components/theme';

import ModalLayout from 'components/modals/ModalLayout';

import { GET_PROFFDATA } from 'api/queries/tenantQueries';

const ProffWrapper = styled.div`
	width: 100%;
	a {
		color: ${theme.palette.primary.main};
	}
`;

const Row = styled.div`
	display: flex;
	align-items: flex-start;
`;
const Divider = styled.hr`
	border-top: 1px solid #d1d1d1;
	margin: 5px 0 15px;
`;
const Label = styled.div`
	flex: 1;
	font-weight: 700;
	padding: 0 5px 5px;
`;
const Value = styled.div`
	flex: 2;
	background: ${p => (p.bg ? '#e1e1e1' : 'transparent')};
	padding: 0 5px 5px;
`;
const PropListWrap = styled.div`
	background: ${p => (p.bg ? '#e1e1e1' : 'transparent')};
	padding: 5px 5px 0;
`;

const ModalAction = styled.div`
	color: ${theme.palette.primary.main};
	cursor: pointer;
	text-decoration: underline;
`;

export default function ProffDataModal({ value }) {
	const [openModal, setOpenModal] = useState();

	const [getProffData, { data: { proffData } = {} }] =
		useLazyQuery(GET_PROFFDATA);

	const handleDataRequest = () => {
		getProffData({ variables: { orgNo: value } });
		setOpenModal(true);
	};

	return (
		<>
			<ModalAction onClick={handleDataRequest}>{value}</ModalAction>
			{openModal &&
				proffData &&
				createPortal(
					<ModalLayout
						title="Data fra proff.no"
						onClose={() => setOpenModal(false)}
						maxWidth="800px"
						width="700px"
					>
						<ProffData data={proffData.proffData} />
					</ModalLayout>,
					document.getElementById('modal-root')
				)}
		</>
	);
}

function ProffData({ data }) {
	return (
		<ProffWrapper>
			<DataRow
				label="Organisasjonsnummer"
				value={data.companyId}
				divider
			/>

			<DataRow label="Navn/foretaksnavn" value={data.name} divider />

			<DataRow
				label="Organisasjonsform"
				value={data.companyTypeName}
				divider
			/>

			<DataRow
				label="Forretningsadresse"
				value={<Address {...data.visitorAddress} />}
				divider
			/>

			<DataRow
				label="Postadresse"
				value={<Address {...data.postalAddress} />}
				divider
			/>

			<DataRow
				label="Næringskode(r)"
				value={data.naceCategories.map((c, i) => (
					<div key={i}>{c}</div>
				))}
				divider
			/>

			<DataRow
				label="Aksjonærer"
				value={data.shareholders.map((p, i) => (
					<PropList
						key={i}
						bg={i % 2 === 0}
						labels={shareholderLabels}
						allowQueries={true}
						{...p}
					/>
				))}
				divider
			/>
		</ProffWrapper>
	);
}

function PropList({ bg = false, labels, allowQueries, ...props }) {
	return (
		<PropListWrap bg={bg}>
			{Object.keys(props)
				.filter(key => props[key] && labels[key])
				.map((key, i) => {
					let value = props[key];
					let label = labels[key];
					if (key === 'companyId' && allowQueries) {
						value = <ProffDataModal label={label} value={value} />;
					} else if (
						key === 'name' &&
						props?.details?.href &&
						!props?.details?.href.includes('api.proff.no')
					) {
						value = (
							<a
								href={props.details.href}
								target="_blank"
								title="Les mer på proff.no"
								rel="noreferrer"
							>
								{value}
							</a>
						);
					} else if (new RegExp('date', 'i').test(key)) {
						value = formatDate(props[key], 'DD.MM.YY', 'DDMMYYYY');
					}

					return (
						<Row key={i}>
							<Label>{label}</Label>
							<Value>{value}</Value>
						</Row>
					);
				})}
		</PropListWrap>
	);
}

function DataRow({ label, value, show = true, divider = false }) {
	if (!value || !show) return null;

	return (
		<>
			<Row>
				<Label>{label}</Label>

				<Value>{value}</Value>
			</Row>

			{divider && <Divider />}
		</>
	);
}

function Address(props) {
	return (
		<div>
			{Object.keys(props)
				.filter(key => props[key] && addressLabels[key])
				.map((key, i) => (
					<Row key={i}>
						<Label>{addressLabels[key]}</Label>

						<Value>{props[key]}</Value>
					</Row>
				))}
		</div>
	);
}

const addressLabels = {
	addressLine: 'Adresse',
	boxAddressLine: 'Postboks',
	postPlace: 'Poststed',
	zipCode: 'Postnr',
};
const shareholderLabels = {
	companyId: 'Orgnr',
	details: null,
	firstName: 'Fornavn',
	lastName: 'Etternavn',
	name: 'Navn',
	numberOfShares: 'Antall aksjer',
	share: 'Andel',
};
