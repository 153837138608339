import { gql } from '@apollo/client';

export const HOME_DASHBOARD_QUERY = gql`
	query homeDashboardData(
		$companyId: ID!
		$createdAt: DateQueryInput
		$type: String
	) {
		company: findOneTenant(_id: $companyId) {
			_id
			name
			clientNo
			orgNo
			phone
			email
			signatories
			procurations
			isVatRegistered
			isAaRegistered
			accountingFirms {
				companyId
				name
				role
				title
			}
			businessAddress {
				streetAddress
				postalCode
				locality
			}
			postalAddress {
				streetAddress
				postalCode
				locality
			}
			accountantIds
			accountants {
				name
			}
			accountManagerIds
			accountManagers {
				name
			}
			payrollManagerIds
			payrollManagers {
				name
			}
			annualAccountsManagerIds
			annualAccountsManagers {
				name
			}
			controllerIds
			controllers {
				name
			}
			auditorIds
			auditors {
				name
			}
			departmentId
			notificationsCount
			categoryIds
			freeText
			isInactive
			userRoles {
				id
				role
			}
			boardMembers(
				roles: [
					"innehaver"
					"dagligleder"
					"nestleder"
					"styreleder"
					"styremedlem"
				]
			) {
				_id
				name
				userRole
			}
			owners {
				user {
					_id
					name
				}
				note
				shareInPercent
			}
			proffData
			dataDeviations
			proffDataDeviationExceptions {
				type
				name
				role
			}
			isFavorite
			updatedAt
			updatedByDisplayName
			annualFinancialStatus {
				readyForReview
				reviewedYear
				reviewedByDisplayName
				reviewedAt
				notRelevant
			}
		}
		mySignatureRequests {
			_id
			signUrl
			documentNode {
				_id
				name
				type
				parentId
				createdAt
				isSigning
				isSigningComplete
				createdBy: createdByDisplayName
				tenantId
				isArchived
				seenByCurrentUser
			}
		}
		unSignedDocuments: paginateDocumentNodes(
			isSigning: true
			isSigningComplete: false
			tenantId: $companyId
			orderBy: "createdAt"
			order: -1
		) {
			count
			items {
				_id
				name
				type
				parentId
				createdAt
				isSigning
				isSigningComplete
				createdBy: createdByDisplayName
				tenantId
				isArchived
				seenByCurrentUser
			}
		}
		recentDocuments: paginateDocumentNodes(
			tenantId: $companyId
			createdAt: $createdAt
			seenByCurrentUser: false
			type: $type
			orderBy: "createdAt"
			order: -1
		) {
			count
			items {
				_id
				name
				type
				parentId
				createdAt
				isSigning
				isSigningComplete
				createdBy: createdByDisplayName
				tenantId
				isArchived
				seenByCurrentUser
			}
		}
	}
`;
