import Widget from 'web/components/widgets/Widget';
import FolderCompanyRoutines from 'web/components/FolderCompanyRoutines';

export default function CompanyRoutinesListWidget({ companyId }) {
	return (
		<Widget title="Bedriftsrutiner" titleIcon="clipboard">
			<FolderCompanyRoutines companyId={companyId} />
		</Widget>
	);
}
