import { useState, forwardRef } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import { formatDate } from 'utils/dates';

const Wrapper = styled.div`
	margin-top: 20px;
`;

const ActionWrapper = styled.div`
	margin-bottom: 20px;
`;

export default function History({ history }) {
	const [showHistory, setShowHistory] = useState(false);
	if (!history || history.length === 0) return null;

	function toggleHistory() {
		setShowHistory(prevHistory => !prevHistory);
	}

	return (
		<Wrapper>
			<ActionWrapper>
				<Button onClick={toggleHistory}>
					{showHistory ? 'Skjul historikk' : 'Vis historikk '}
				</Button>
			</ActionWrapper>

			<Collapse in={showHistory}>
				{showHistory && <HistoryListWithRef history={history} />}
			</Collapse>
		</Wrapper>
	);
}

const Byline = styled.span`
	color: ${props => props.theme.palette.status.subdued};
	font-size: 14px;
	margin-bottom: 5px;
`;

const Panel = styled.div`
	background: ${props => props.theme.palette.background.gray};
`;

/**
 * The transition components require the first child element to forward its ref to the DOM node.
 * TODO: This may change in React 19, where the ref will be forwarded automatically as a prop.
 *
 *  @link  https://mui.com/material-ui/transitions/#child-requirement
 */
const HistoryListWithRef = forwardRef(function HistoryList({ history }, ref) {
	return (
		<Panel ref={ref}>
			<List>
				{history.map((item, index) => (
					<ListItem key={index} dense>
						<ListItemText
							primary={item.description}
							secondary={
								<Byline>
									{formatDate(
										item.createdAt,
										'DD.MM.YYYY [kl] HH:mm'
									)}{' '}
									av {item.createdByDisplayName}
								</Byline>
							}
						/>
					</ListItem>
				))}
			</List>
		</Panel>
	);
});
