import { useState, createContext, useContext } from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { Link, useNavigation } from 'app/components/Navigator';
import Icon from 'components/Icon';
import logo from 'assets/images/logo.svg';

import { useApp } from 'app/contexts/AppProviders';

const StyledSwipeableDrawer = styled(SwipeableDrawer)`
	.MuiBackdrop-root {
		background: rgba(41, 20, 25, 0.15);
	}
`;
const SidebarWrapper = styled.div`
	width: 300px;
	background: white;
	min-height: 100%;
`;
const SidebarScrollWrapper = styled.div`
	position: absolute;
	overflow: auto;
	top: 78px;
	bottom: 121px;
	padding-bottom: 140px;
	left: 0;
	right: 0;
	width: 100%;
`;
const BottomSidebarPush = styled.div`
	height: 40px;
`;
const SidebarHeader = styled.div`
	padding: calc(12px + env(safe-area-inset-top)) 15px 11px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #e5e5e5;
	margin-bottom: 5px;
`;
const SidebarBottomSection = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	background: white;
	padding-bottom: 34px;
	border-top: 1px solid #e5e5e5;
`;
// const Logo = styled.img`
// 	width: 119px;
// 	height: 34px;
// `;

const Logo = styled.img`
	width: 100%;
	height: auto;
	max-width: 119px;
`;
const CloseIcon = styled(Icon)`
	color: ${p => p.theme.palette.primary.main};
	font-size: 20px;
`;
const StyledLink = styled(Link)`
	width: 100%;
	padding: 15px;
	color: #000000;
	text-align: left;
	text-decoration: none;
	background: none;
	transition: background 300ms;
	position: relative;

	&:hover {
		background: #fcf9f9;
	}

	&.self--active {
		background: #fcf9f9;
	}

	.material-icons {
		width: 30px;
		height: 30px;
		text-align: center;
		&:before {
			line-height: 30px;
		}
	}
`;
const LinkLabel = styled.span`
	color: #000000;
	font-size: 18px;
	font-weight: 400;
	line-height: 30px;
	position: absolute;
	left: 52px;
	top: 9px;
`;

const MainMenuContext = createContext({
	isOpen: false,
	setIsOpen: () => {},
});

export function useMainMenu() {
	const context = useContext(MainMenuContext);

	if (context === undefined) {
		throw new Error('useMainMenu must be used within a MainMenuContext');
	}

	return context;
}

export default function MainMenuProvider({ children }) {
	const [isOpen, setIsOpen] = useState(false);
	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
	const {
		set,
		data: { user },
	} = useApp();

	const { currentScreen } = useNavigation();

	return (
		<MainMenuContext.Provider
			value={{
				isOpen,
				setIsOpen,
			}}
		>
			{children}

			<StyledSwipeableDrawer
				anchor="right"
				open={isOpen}
				onClose={() => setIsOpen(false)}
				onOpen={() => setIsOpen(true)}
				disableDiscovery={iOS}
				ModalProps={{
					style: {
						backgroundColor: 'transparent',
					},
				}}
			>
				<SidebarWrapper
					onClick={() => {
						setIsOpen(false);
					}}
				>
					<SidebarHeader>
						<Logo src={logo} title="Admento AS" />

						<IconButton
							aria-label="Lukk meny"
							onClick={() => setIsOpen(false)}
						>
							<CloseIcon icon="times" />
						</IconButton>
					</SidebarHeader>

					<SidebarScrollWrapper>
						<StyledLink reset>
							<Icon
								icon="home"
								solid={currentScreen.screen === 'Home'}
							/>
							<LinkLabel>Hjem</LinkLabel>
						</StyledLink>

						<StyledLink
							onTopOfRoot
							to={{
								screen: 'Document',
								documentId: 'root',
								name: 'Dokumenter',
								type: 'folder',
							}}
						>
							<Icon
								icon="folder"
								solid={currentScreen.screen === 'Document'}
							/>
							<LinkLabel>Dokumenter</LinkLabel>
						</StyledLink>

						<StyledLink
							onTopOfRoot
							to={{
								screen: 'ChatArchive',
							}}
						>
							<Icon
								icon="comment"
								solid={currentScreen.screen === 'ChatArchive'}
							/>
							<LinkLabel>Chat</LinkLabel>
						</StyledLink>

						<BottomSidebarPush />
					</SidebarScrollWrapper>
				</SidebarWrapper>

				<SidebarBottomSection
					onClick={() => {
						setIsOpen(false);
					}}
				>
					{user &&
						(user.role === 'admin' ||
							user.role === 'accountant') && (
							<StyledLink
								onTopOfRoot
								to={{ screen: 'CompanyEdit' }}
							>
								<Icon icon="edit" />
								<LinkLabel>Rediger bedrift</LinkLabel>
							</StyledLink>
						)}

					<StyledLink
						onClick={() => {
							set({
								tenantId: null,
							});
						}}
					>
						<Icon icon="building" />
						<LinkLabel>Mine bedrifter</LinkLabel>
					</StyledLink>

					<StyledLink
						onTopOfRoot
						to={{
							screen: 'MyProfile',
						}}
					>
						<Icon icon="user" />
						<LinkLabel>Min profil</LinkLabel>
					</StyledLink>

					<StyledLink
						onClick={() => {
							set({
								apiToken: null,
								user: null,
								tenantId: null,
							});
						}}
					>
						<Icon icon="sign-out-alt" />
						<LinkLabel>Logg ut</LinkLabel>
					</StyledLink>
				</SidebarBottomSection>
			</StyledSwipeableDrawer>
		</MainMenuContext.Provider>
	);
}
