import { createContext, useState, useContext, useEffect } from 'react';
import {
	ApolloProvider,
	ApolloClient,
	ApolloLink,
	HttpLink,
	concat,
	InMemoryCache,
	defaultDataIdFromObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ThemeProvider } from 'styled-components';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import theme from 'components/theme';

import ConfirmProvider from 'components/contexts/ConfirmContext';
import MoveDocumentNodeModalProvider from 'components/contexts/MoveDocumentNodeModalContext';

import SnackbarProvider from 'web/contexts/SnackbarContext';
import AppFiltersProvider from 'web/contexts/AppFiltersContext';
import UserProvider from 'web/contexts/UserContext';
import RenameDocumentNodeModalProvider from 'web/contexts/RenameDocumentNodeModalContext';

import IdleTimer from 'utils/idleTimer';

const APP_DATA_KEY = process.env.REACT_APP_DATA_KEY || 'admento-web';
let APP_DATA = null;

try {
	const localData = JSON.parse(localStorage.getItem(APP_DATA_KEY));

	APP_DATA = {
		...localData,
		isSidebarOpen: localData?.isSidebarOpen ?? true,
	};
} catch (error) {
	console.warn(error);
}

export function parseJwt(token) {
	if (!token) return;

	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const decoded = JSON.parse(decodeURIComponent(atob(base64)));

	return decoded;
}

const WebContext = createContext({
	set: () => {},
	logout: () => {},
	data: APP_DATA,
});

export const useWebApp = () => useContext(WebContext);

export default function WebProviders({ children }) {
	const [data, _setData] = useState(APP_DATA || {});
	const [isTimeout, _setIsTimeout] = useState(false);
	const timeout = process.env.REACT_APP_INACTIVITY_LIMIT_SECONDS || 14400; // 4 hours

	const httpLink = new HttpLink({
		uri: window.location.origin + '/graphql',
		credentials: 'same-origin',
	});

	const authLink = new ApolloLink((operation, forward) => {
		if (data?.apiToken) {
			operation.setContext({
				headers: {
					Authorization: data?.apiToken && 'Bearer ' + data.apiToken,
					Platform: 'web',
				},
			});
		}

		return forward(operation);
	});

	const errorLink = onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors)
			graphQLErrors.forEach(({ message, locations, path }) =>
				console.warn(
					`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
				)
			);

		if (networkError) console.warn(`[Network error]: ${networkError}`);
	});

	const cache = new InMemoryCache({
		dataIdFromObject: object => {
			switch (object.__typename) {
				case 'UserRole':
					return `${object.id}-${object.role}`;
				case 'UserTenantRole':
					return `${object.key}-${object.role}`;
				default:
					return defaultDataIdFromObject(object);
			}
		},
	});

	const apolloClient = new ApolloClient({
		link: concat(authLink, httpLink, errorLink),
		cache,
		defaultOptions: {
			watchQuery: {
				fetchPolicy: 'cache-and-network',
			},
		},
	});

	function setData(values) {
		// if (data?.apiToken && !data?.user) {
		// 	values.user = parseJwt(data.apiToken);
		// }

		const updatedData = { ...data, ...values };

		localStorage.setItem(APP_DATA_KEY, JSON.stringify(updatedData));

		_setData(updatedData);
	}

	function resetAllUserData() {
		setData({
			apiToken: null,
			user: null,
		});
	}

	useEffect(() => {
		const timer = new IdleTimer({
			timeout,
			onTimeout: () => {
				setData({ apiToken: null, user: null });
				_setIsTimeout(true);
			},
			onExpired: () => {
				setData({ apiToken: null, user: null });
				_setIsTimeout(true);
			},
		});

		return () => {
			timer?.cleanUpAll();
		};
	}, []);

	const tokenData = parseJwt(data.apiToken);

	if ((tokenData?.exp && Date.now() / 1000 > tokenData?.exp) || isTimeout) {
		_setIsTimeout(false);

		resetAllUserData();
	}

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline enableColorScheme />

			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb">
				<ApolloProvider client={apolloClient}>
					<WebContext.Provider
						value={{
							data,
							set: setData,
							logout: resetAllUserData,
						}}
					>
						<SnackbarProvider>
							<UserProvider key={data?.apiToken}>
								<MoveDocumentNodeModalProvider>
									<RenameDocumentNodeModalProvider>
										<ConfirmProvider>
											<AppFiltersProvider>
												{children}
											</AppFiltersProvider>
										</ConfirmProvider>
									</RenameDocumentNodeModalProvider>
								</MoveDocumentNodeModalProvider>
							</UserProvider>
						</SnackbarProvider>
					</WebContext.Provider>
				</ApolloProvider>
			</LocalizationProvider>
		</ThemeProvider>
	);
}
