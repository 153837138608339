import UserVerifyToken from './UserVerifyToken';

export default function UserVerifyPhone({ _id, verificationPhone }) {
	return (
		<UserVerifyToken
			type="phone"
			userId={_id}
			verificationValue={verificationPhone}
			pageTitle="Verifiser tlf"
			title="Du må verifisere telefonnummeret ditt"
			firstStepContent="For å kunne motta meldinger fra oss, må du registrere et mobilnummer du har tilgang til. Sjekk at nummeret stemmer, så sender vi en verifiseringskode til det."
			lastStepContent="Fyll ut verifiseringskoden du har fått tilsendt på SMS."
			changeValueText="Endre telefonnummer"
		/>
	);
}
