import Widget from 'web/components/widgets/Widget';
import DocumentItem from 'web/components/widgets/components/DocumentItem';

export default function SigningRequestsWidget({
	title,
	titleIcon = null,
	loading = false,
	signingRequests,
}) {
	if (!signingRequests || signingRequests.length === 0) {
		return null;
	}
	return (
		<Widget title={title} titleIcon={titleIcon} loading={loading}>
			{signingRequests.map(signingRequests => (
				<DocumentItem
					key={signingRequests._id}
					doc={signingRequests.documentNode}
					href={signingRequests.signUrl}
				/>
			))}
		</Widget>
	);
}
