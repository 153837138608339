import { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ErrorMessage from 'components/ErrorMessage';

import { EmailLink, PhoneLink } from 'web/components/Link';

import { GET_COMPANY_ROUTINES } from 'api/queries/companyRoutineQueries';
import { FIND_ONE_USER } from 'api/queries/userQueries';

export default function FolderCompanyRoutines({ folderId, companyId }) {
	const [expanded, setExpanded] = useState(false);

	const { data, loading, error } = useQuery(GET_COMPANY_ROUTINES, {
		fetchPolicy: 'cache-and-network',
		errorPolicy: 'all',
		variables: {
			folderId,
			companyId,
		},
	});

	if (loading || !data.routines) {
		return null;
	}

	const handleAccordionChange = panel => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<div>
			<ErrorMessage errors={error} />

			{data.routines.map(routine => (
				<Accordion
					key={routine._id}
					expanded={expanded === routine._id}
					onChange={handleAccordionChange(routine._id)}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls={`${routine._id}-content`}
						id={`${routine._id}-header`}
					>
						{routine.name}
					</AccordionSummary>

					<AccordionDetails>
						<div>
							{routine.fields.map(field => (
								<div key={field.name}>
									{renderRoutineField(field)}
								</div>
							))}
						</div>
					</AccordionDetails>
				</Accordion>
			))}
		</div>
	);
}

const Routine = styled.div`
	margin-bottom: 20px;
`;

const Label = styled.h4`
	margin: 0 0 10px 0;
`;

const Value = styled.div`
	margin: 0;
`;

const Name = styled.div`
	margin-bottom: 5px;
`;

function renderRoutineField(field) {
	if (!field.value) return null; // Don't render empty fields.

	switch (field.type) {
		case 'text':
			return <RoutineTextField field={field} />;
		case 'user':
			return <RoutineUserField field={field} />;
		default:
			return 'Dette feltet er ikke støttet!';
	}
}

function RoutineTextField({ field }) {
	return (
		<Routine>
			<Label>{field.label}</Label>

			<Value>{field.value}</Value>
		</Routine>
	);
}

function RoutineUserField({ field }) {
	const {
		data: { user = {} } = { user: {} },
		loading,
		error,
	} = useQuery(FIND_ONE_USER, {
		variables: {
			_id: field.value,
		},
	});

	if (loading) {
		return null;
	}

	return (
		<Routine>
			<ErrorMessage errors={error} />

			<Label>{field.label}</Label>

			<Value>
				<Name>{user.name}</Name>

				<table>
					<tbody>
						<tr>
							<td>Tlf:</td>

							<td>
								<PhoneLink phone={user.phone} />
							</td>
						</tr>

						<tr>
							<td>E-post:</td>

							<td>
								<EmailLink email={user.email} />
							</td>
						</tr>
					</tbody>
				</table>
			</Value>
		</Routine>
	);
}
