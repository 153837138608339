import { Controller, useFormContext } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MUICheckbox from '@mui/material/Checkbox';

export default function CheckboxGroup({
	name,
	label,
	legend = null,
	required = false,
	...rest
}) {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<div>
					<FormControl
						required={required}
						error={!!error}
						component="fieldset"
						variant="outlined"
					>
						{legend && (
							<FormLabel component="legend">{legend}</FormLabel>
						)}

						<FormGroup>
							<FormControlLabel
								label={label}
								control={
									<MUICheckbox
										checked={value ?? false}
										onChange={event =>
											onChange(event.target.checked)
										}
									/>
								}
								{...rest}
							/>
						</FormGroup>

						{error && (
							<FormHelperText>{error.message}</FormHelperText>
						)}
					</FormControl>
				</div>
			)}
		/>
	);
}
