import styled from 'styled-components';

import AppFilters from 'components/filters/AppFilters';

const SidebarContent = styled.div``;

const SidebarTitle = styled.h2`
	margin-top: 0;
`;

export default function CompaniesArchiveSidebar({ displayFilters = [] }) {
	return (
		<SidebarContent>
			<SidebarTitle>Filtrering</SidebarTitle>

			<AppFilters
				displayFilters={displayFilters}
				size="small"
				direction="column"
			/>
		</SidebarContent>
	);
}
