import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { formatDate } from 'utils/dates';

import DocumentIcon from 'components/DocumentIcon';
import ListItemMenu from 'app/components/ListItemMenu';
import Icon from 'components/Icon';
import {
	MenuWrapper,
	MenuHeader,
	CloseButton,
	BottomMenuItem,
} from 'app/components/BottomMenu';

const StyledBottomMenuItem = styled(BottomMenuItem)`
	${props =>
		props.active &&
		css`
			background: #fcf9f9;
		`}
`;
const StyledList = styled.section`
	position: relative;
	margin-bottom: 30px;
	${p =>
		p.emphasized &&
		css`
			background-color: #faeaee;
			margin-left: -15px;
			margin-right: -15px;
			padding: 3px 15px 15px 15px;
		`}

	.far,
	.fa-regular {
		font-weight: 800;
	}

	.shrink-up-enter {
		opacity: 1;
	}

	.shrink-up-enter.shrink-up-enter-active {
		opacity: 1;
		transition: all 250ms cubic-bezier(0, 0, 0.2, 1);
	}

	.shrink-up-exit {
		opacity: 1;
		transform: scaleY(1);
		transform-origin: 0;
		max-height: 120px;
	}

	.shrink-up-exit-active {
		opacity: 0;
		transform: scaleY(0);
		transition: all 250ms cubic-bezier(0.4, 0, 1, 1);
		transform-origin: top left;
		max-height: 0px;
	}
`;
const ListTitle = styled.div`
	color: ${p => (p.emphasized ? '#4f4f4f' : p.theme.palette.text.secondary)};
	font-size: ${15 / 16}rem;
	font-weight: 700;
	line-height: 1.666666667;
	margin-bottom: ${p => (p.isContracted ? '0' : '10px')};

	${p =>
		p.contractable &&
		css`
			&:after {
				display: block;
				content: '▼';
				float: right;
				transform: scaleY(${p => (p.isContracted ? '-1' : '1')});
				transition: transform 0.2s;
			}
		`}
`;
const ListItem = styled.div`
	background: ${p => p.theme.palette.background.default};
	display: flex;
	align-items: center;
	color: ${p => p.theme.palette.text.primary};
	padding: 11px 15px;
	line-height: 1;
	min-height: 70px;
	margin-bottom: 3px;
	max-width: 375px;
	width: 100%;
	flex-basis: 100%;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
	opacity: ${p => (p.isHidden ? 0.5 : 1)};
	transition: all 0.25s;

	${p =>
		p.isContracted &&
		css`
			min-height: 0;
			height: 0;
			max-height: 0;
			overflow: hidden;
			margin: 0;
			padding: 0 15px;
			box-shadow: 0 0 0 rgba(0, 0, 0, 0);
		`}

	&.active {
		color: ${p => p.theme.palette.primary.main} !important;
	}

	&.signed {
		color: #0b8806;
	}
`;
const ListItemLoadingElement = styled.div`
	background: ${p => p.theme.palette.background.default};
	display: flex;
	margin-bottom: 3px;
	box-shadow: 0 3px 10px rgba(157, 0, 40, 0.04);
	min-height: 70px;
`;
const ListItemLink = styled.a`
	color: ${p => p.theme.palette.text.primary};
	flex: 1 1 0%;
	text-decoration: none;
	outline: none;
`;
const ListItemButton = styled.button`
	color: ${p => p.theme.palette.text.primary};
	flex: 1 1 0%;
	text-decoration: none;
	background: none;
	border: none;
	text-align: left;
	outline: none;
	padding: 0;
`;
const Document = styled.div`
	display: flex;
	align-items: center;
`;
const StyledDocumentIcon = styled(DocumentIcon)`
	&:before {
		font-size: 20px;
	}
`;
const DocumentInfo = styled.div`
	margin-left: 10px;
`;
const DocumentName = styled.div`
	font-size: ${18 / 16}rem;
	font-weight: ${p => (p.bold ? 'bold' : 'normal')};
	color: #1a1a1a;
	line-height: 24px;
	word-break: break-all;
	margin: 5px 0 10px;
`;
const DocumentMeta = styled.div`
	font-size: ${13 / 16}rem;
	line-height: 10px;
	margin: 5px 0;
	opacity: 0.75;
`;

function ListItemContent({
	name,
	isSigning,
	isSigningComplete,
	type,
	createdAt,
	createdBy,
	format = 'DD.MM.YYYY',
	seenByCurrentUser,
}) {
	return (
		<Document>
			<StyledDocumentIcon
				needsSignature={isSigning}
				hasSignature={isSigningComplete}
				type={type}
			/>

			<DocumentInfo>
				<DocumentName bold={!seenByCurrentUser}>{name}</DocumentName>

				<DocumentMeta>
					{formatDate(createdAt, format)}{' '}
					{createdBy && ' av ' + createdBy}
				</DocumentMeta>
			</DocumentInfo>
		</Document>
	);
}

export function ListItemElement({
	menuItems,
	generateLink,
	isContracted,
	onClick,
	...item
}) {
	return (
		<CSSTransition
			classNames="shrink-up"
			timeout={{ enter: 300, exit: 300 }}
		>
			<ListItem
				key={item._id}
				isContracted={isContracted}
				isHidden={item.isHidden}
				className={
					(item.isSigning && !item.isSigningComplete && 'active') ||
					(item.isSigning && item.isSigningComplete && 'signed') ||
					''
				}
			>
				{generateLink && (
					<ListItemLink to={generateLink(item)}>
						<ListItemContent {...item} />
					</ListItemLink>
				)}

				{!generateLink && (
					<ListItemButton onClick={onClick}>
						<ListItemContent {...item} />
					</ListItemButton>
				)}

				{menuItems && <ListItemMenu>{menuItems}</ListItemMenu>}
			</ListItem>
		</CSSTransition>
	);
}

const SortingWrapper = styled.div``;

const SortingButton = styled.button`
	position: absolute;
	top: 0;
	right: 0;
	font-family: Mukta, sans-serif;
	color: ${({ theme }) => theme.palette.primary.main};
	font-size: 15px;
	font-weight: 700;
	text-transform: uppercase;
	background: none;
	border: none;
	cursor: pointer;
`;

function SortingSection({ options, direction, value, onChange, isContracted }) {
	const [isOpen, setIsOpen] = useState(false);
	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

	return (
		<SortingWrapper isContracted={isContracted}>
			<SortingButton
				onClick={() => {
					// TODO: Open sorting modal
					setIsOpen(true);
				}}
			>
				{options[value]}
				<Icon
					icon={'sort-amount-' + ((direction > 0 && 'up') || 'down')}
					style={{
						marginLeft: '10px',
					}}
				/>
			</SortingButton>
			<SwipeableDrawer
				anchor="bottom"
				open={isOpen}
				onClose={() => setIsOpen(false)}
				onOpen={() => setIsOpen(true)}
				disableDiscovery={iOS}
				style={{ background: 'white' }}
			>
				<MenuWrapper>
					<MenuHeader>
						Sorter etter..
						<CloseButton onClick={() => setIsOpen(false)}>
							<Icon icon="xmark" />
						</CloseButton>
					</MenuHeader>
					{Object.keys(options).map(key => (
						<StyledBottomMenuItem
							key={key}
							active={value === key}
							onClick={() => {
								onChange && onChange(key);
								setIsOpen(false);
							}}
						>
							{options[key]}
						</StyledBottomMenuItem>
					))}
				</MenuWrapper>
			</SwipeableDrawer>
		</SortingWrapper>
	);
}

export default function List({
	title,
	emphasized,
	contractable = false,
	items = [],
	error,
	loading,
	emptyElement,
	sortingOptions,
	currentSorting,
	sortingDirection,
	onSortingChange,
	children,
	onClickItem,
}) {
	let listParams = { emphasized };
	let listTitleParams = { emphasized, contractable };
	let listContentParams = { contractable };
	const [isContracted, setIsContracted] = useState(() => {
		if (!contractable) return false;

		const contractableLists =
			(JSON.parse(localStorage.getItem('_falstad.system')) || {})
				.contractableLists || [];
		const currentListIndex = contractableLists.findIndex(
			list => list.title === title
		);
		if (currentListIndex > -1) {
			return contractableLists[currentListIndex].isContracted;
		}
		return false;
	});

	useEffect(() => {
		let localSystem =
			JSON.parse(localStorage.getItem('_falstad.system')) || {};
		const contractableLists = localSystem.contractableLists || [];

		const currentListIndex = contractableLists.findIndex(
			list => list.title === title
		);

		if (currentListIndex > -1) {
			contractableLists[currentListIndex].isContracted = isContracted;
		} else {
			contractableLists.push({
				title,
				isContracted,
			});
		}

		localSystem.contractableLists = contractableLists;
		localStorage.setItem('_falstad.system', JSON.stringify(localSystem));

		return;
	}, [isContracted]);

	listTitleParams = {
		...listTitleParams,
		onClick: () => setIsContracted(!isContracted),
		isContracted,
	};

	listContentParams = {
		...listContentParams,
		isContracted,
	};

	return (
		<StyledList {...listParams}>
			<ListTitle {...listTitleParams}>{title}</ListTitle>
			{sortingOptions && (
				<SortingSection
					options={sortingOptions}
					direction={sortingDirection}
					value={currentSorting}
					onChange={onSortingChange}
					{...listContentParams}
				/>
			)}

			{error && <span>{error}</span>}

			{loading && (
				<>
					<ListItemLoadingElement />
					<ListItemLoadingElement />
					<ListItemLoadingElement />
				</>
			)}

			<TransitionGroup>
				{items.map(item => (
					<ListItemElement
						key={item._id}
						contractable={contractable}
						onClick={() => {
							onClickItem && onClickItem(item);
						}}
						{...item}
						{...listContentParams}
					/>
				))}

				{(!items || !items.length) && !loading && emptyElement}
			</TransitionGroup>
			{!isContracted && children}
		</StyledList>
	);
}
