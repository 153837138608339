import { useState, useEffect } from 'react';
import styled from 'styled-components';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { Content as LayoutContent } from 'web/components/Layout';
import Icon from 'components/Icon';

const Layout = styled(LayoutContent)`
	&.permanent {
		display: grid;
		grid-template-columns: 1fr ${p => p.$sidebarWidth}px;
		grid-auto-rows: auto; /* Allow rows to be auto height */

		gap: 30px;
	}
`;

const Backdrop = styled.div`
	display: none;
	.drawer-open & {
		display: block;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 999;
	}
`;

const Content = styled.div`
	.permanent & {
		border-right: 1px solid ${p => p.theme.palette.divider};
		padding-right: 30px;
	}
`;

const Drawer = styled.div`
	width: 100%;
	&:not(.permanent) {
		position: fixed;
		right: 0;
		top: 0;
		z-index: 1051;
		height: 100vh;
		overflow-y: auto;
		width: 300px;
		background-color: ${p => p.theme.palette.background.gray};
		border-left: 1px solid ${p => p.theme.palette.divider};
		transition: transform 0.3s ease;
		transform: translateX(100%);
		padding: 30px;

		&.drawer-open {
			transform: translateX(0);
		}
	}
`;

const StickyContent = styled.div`
	.permanent & {
		position: sticky;
		top: 0;
	}
`;

const StickyInnerContent = styled.div`
	max-height: calc(100vh - 100px);
	padding-top: 10px;
	margin-top: -10px; // Trick to let the sticky content have some padding top
`;

const OpenButtonContainer = styled.div`
	background-color: ${p => p.theme.palette.primary.main};
	position: fixed;
	top: 92px;
	right: 0;
	z-index: 1050;
	padding: 10px 0;
	border-top-left-radius: 50%;
	border-bottom-left-radius: 50%;

	button {
		padding-right: 0;
	}
	i {
		color: ${p => p.theme.palette.primary.contrastText};
	}
`;

const CloseButton = styled(IconButton)`
	position: absolute;
	right: 0px;
	top: 0px;
	font-size: 1.2rem;
`;

/**
 * ContentWithDrawer Component
 *
 * This component renders a layout with a main content area and a sidebar drawer.
 * The sidebar drawer can be either permanently visible or toggleable based on the window width.
 *
 * @component
 * @param {React.ReactNode} children - The main content to be displayed.
 * @param {React.ReactNode} drawer - The content to be displayed inside the drawer.
 * @param {number} [sidebarWidth=300] - The width of the sidebar drawer.
 * @param {number} [permanentVisibleBreakpoint=1920] - The window width at which the drawer becomes permanently visible.
 * @param {string} [openIcon='filter-list'] - The icon to be used for the open button.
 * @param {string} [openLabel='Åpne filtrering'] - The label for the open button tooltip.
 * @param {Object} [props] - Additional props to be passed to the Layout component.
 * @returns {JSX.Element} The rendered component.
 */
export default function ContentWithDrawer({
	children,
	drawer,
	sidebarWidth = 300,
	permanentVisibleBreakpoint = 1920,
	openIcon = 'filter-list',
	openLabel = 'Åpne filtrering',
	...props
}) {
	const [open, setOpen] = useState(false);
	const [isPermanent, setIsPermanent] = useState(
		window.innerWidth >= permanentVisibleBreakpoint
	);

	const handleClose = () => {
		setOpen(false);
	};

	// Set className based on states
	const getLayoutClass = () => {
		let className = '';

		className += open ? 'drawer-open' : '';
		className += isPermanent ? ' permanent' : '';

		return className.trim();
	};

	useEffect(() => {
		// Set isPermanent based on window width
		const handleResize = () => {
			setIsPermanent(window.innerWidth >= permanentVisibleBreakpoint);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<>
			<Layout
				$sidebarWidth={sidebarWidth}
				className={getLayoutClass()}
				{...props}
			>
				{!isPermanent && (
					<>
						<BackdropComponent handleClose={handleClose} />

						<OpenButtonContainer>
							<Tooltip title={openLabel} placement="left">
								<IconButton
									onClick={() => setOpen(true)}
									size="small"
								>
									<Icon icon={openIcon} size="sm" />
								</IconButton>
							</Tooltip>
						</OpenButtonContainer>
					</>
				)}

				<Content>{children}</Content>

				<Drawer className={getLayoutClass()}>
					{!isPermanent && (
						<CloseButton onClick={handleClose}>
							<Icon icon="times" />
						</CloseButton>
					)}

					<StickyContent>
						<StickyInnerContent>{drawer}</StickyInnerContent>
					</StickyContent>
				</Drawer>
			</Layout>
		</>
	);
}

// Isolated backdrop component
const BackdropComponent = ({ handleClose }) => {
	useEffect(() => {
		// Close with ecs key
		const handleKeyDown = e => {
			if (e.key === 'Escape') {
				handleClose();
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	return <Backdrop onClick={handleClose} />;
};
