import { createContext, useContext } from 'react';
import { useQuery } from '@apollo/client';

import { CURRENT_USER } from 'api/queries/userQueries';

export const UserContext = createContext({
	user: null,
});

export const useUser = () => useContext(UserContext);

export default function UserProvider({ children }) {
	const { data: { currentUser } = { currentUser: null }, loading } = useQuery(
		CURRENT_USER,
		{
			fetchPolicy: 'network-only',
		}
	);

	return (
		<UserContext.Provider
			value={{
				user: currentUser,
				userLoading: loading,
			}}
		>
			{children}
		</UserContext.Provider>
	);
}
