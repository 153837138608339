import { useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';

import { validateEmail, validatePhone } from 'utils/strings';

import Icon from 'components/Icon';
import ErrorMessage from 'components/ErrorMessage';

import AutocompleteField from 'components/form/AutocompleteField';

import { FIND_TENANT_USERS } from 'api/queries/userQueries';

const SigneeWrapper = styled.div`
	position: relative;
`;

const FieldWrapper = styled.div`
	margin-bottom: 16px;
`;

const SigneeHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const SigneeLabel = styled.span`
	font-size: 15px;
	font-weight: 700;
	display: inline-block;
`;

const RemoveButton = styled(Button)`
	&& {
		position: absolute;
		right: -40px;
		top: 7px;
		width: 24px;
		height: 24px;
		min-width: 24px;
	}
`;

export default function SigneeEditor({
	label,
	onRemove,
	onChange,
	disabled,
	signee: { key, email, phone, userId },
	allSignees = [],
	companyId,
}) {
	const [isUser, setIsUser] = useState(userId ? true : false);
	const [touched, setTouched] = useState({
		email: false,
		phone: false,
		userId: false,
	});
	const {
		data: { users: tenantUsers } = { users: null },
		loading: tenantUsersLoading,
		error: tenantUsersError,
	} = useQuery(FIND_TENANT_USERS, {
		variables: {
			tenantId: companyId,
		},
		errorPolicy: 'all',
	});

	return (
		<SigneeWrapper>
			<ErrorMessage errors={tenantUsersError} />

			<SigneeHeader>
				<SigneeLabel>{label}</SigneeLabel>

				<FormControlLabel
					control={
						<Switch
							checked={isUser}
							onChange={() => {
								onChange({
									key,
									phone: '',
									email: '',
									userId: '',
								});

								setIsUser(!isUser);
							}}
						/>
					}
					label="Velg bruker"
					labelPlacement="start"
				/>
			</SigneeHeader>
			<RemoveButton
				onClick={onRemove}
				color="primary"
				disabled={disabled}
			>
				<Icon icon="trash" />
			</RemoveButton>
			{!isUser && (
				<>
					<FieldWrapper>
						<TextField
							disabled={disabled}
							type="email"
							fullWidth
							label="E-postadresse"
							error={touched.email && !validateEmail(email)}
							value={email}
							onBlur={() => {
								setTouched({ ...touched, email: true });
							}}
							onChange={e => {
								onChange({
									key,
									phone,
									email: e.target.value,
									userId: '',
								});
							}}
						/>
					</FieldWrapper>

					<FieldWrapper>
						<TextField
							disabled={disabled}
							type="tel"
							fullWidth
							label="Telefonnummer"
							value={phone}
							error={touched.phone && !validatePhone(phone)}
							onBlur={() => {
								setTouched({ ...touched, phone: true });
							}}
							onChange={e => {
								onChange({
									key,
									email,
									phone: e.target.value,
									userId: '',
								});
							}}
						/>
					</FieldWrapper>
				</>
			)}
			{isUser && !tenantUsersLoading && !tenantUsersError && (
				<FieldWrapper>
					<AutocompleteField
						value={userId}
						fullWidth
						options={tenantUsers.items.map(user => {
							return {
								label: user.name,
								value: user._id,
								disabled:
									!user.email ||
									!user.phone ||
									allSignees.filter(
										signee => signee.userId === user._id
									).length > 0,
								tooltip: [
									<Stack
										key="1"
										direction="row"
										alignItems="center"
										gap={1}
									>
										<MailIcon />
										{user.email || 'Mangler e-post'}
									</Stack>,
									<Stack
										key="2"
										direction="row"
										alignItems="center"
										gap={1}
									>
										<PhoneIcon />
										{user.phone || 'Mangler telefon'}
									</Stack>,
								],
							};
						})}
						update={user => {
							onChange({
								key,
								email: '',
								phone: '',
								userId: user.value,
							});
						}}
						clearable={false}
					/>
				</FieldWrapper>
			)}
		</SigneeWrapper>
	);
}
