import styled from 'styled-components';

const StyledCard = styled.section`
	padding-bottom: 8px;
`;
const Image = styled.div`
	background-color: #e6e6e6;
	background-image: url(${p => p.src});
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	width: 120px;
	height: 120px;
	border: 4px solid #fff;
	border-radius: 100%;
	margin: 0 auto;
	z-index: 2;
	position: relative;
`;
const IconWrapper = styled.div`
	background-color: #e6e6e6;
	width: 120px;
	height: 120px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 4px solid #fff;
	box-sizing: border-box;
	flex-basis: 100%;
	border-radius: 100%;
	z-index: 2;
	position: relative;
`;
const Content = styled.div`
	background-color: #fff;
	//box-shadow: 0 3px 10px rgba(157, 0, 40, 0.04);
	padding: 70px 15px 15px;
	margin-top: -60px;
	z-index: 1;
`;
const TitleWrapper = styled.div`
	text-align: center;
	margin-bottom: 34px;
`;
const Title = styled.span`
	font-size: 18px;
	font-weight: 700;
`;
const SubTitle = styled.span`
	font-size: 13px;
	color: #919191;
	display: block;
	width: 100%;
`;

export default function Card({
	image = null,
	icon = null,
	children,
	contentStyle,
	style,
	title,
	subTitle,
}) {
	return (
		<StyledCard style={style}>
			{image && <Image src={image} />}
			{icon && <IconWrapper>{icon}</IconWrapper>}

			<Content style={contentStyle}>
				{(title || subTitle) && (
					<TitleWrapper>
						{title && <Title>{title}</Title>}
						{subTitle && <SubTitle>{subTitle}</SubTitle>}
					</TitleWrapper>
				)}
				{children}
			</Content>
		</StyledCard>
	);
}
