export default function idleTimer({ timeout, onTimeout, onExpired }) {
	const STORAGE_KEY = '_admento.expiredTime';

	const expiredTime = parseInt(localStorage.getItem(STORAGE_KEY), 10);
	const trackingHandler = updateExpiryTime;

	trackingListeners();

	let timeoutTracker = updateExpiryTime();
	let intervalTracker = startInterval();

	if (expiredTime > 0 && expiredTime < Date.now()) {
		onExpired();
		return;
	}

	function startInterval() {
		updateExpiryTime(timeoutTracker);

		const interval = setInterval(() => {
			const expiredTime = parseInt(localStorage.getItem(STORAGE_KEY), 10);

			if (expiredTime && expiredTime < Date.now()) {
				if (onTimeout) {
					onTimeout();
					cleanUp();
				}
			}
		}, 1000);

		return interval;
	}

	function updateExpiryTime(tracker) {
		if (tracker) clearTimeout(tracker);

		tracker = setTimeout(() => {
			localStorage.setItem(STORAGE_KEY, Date.now() + timeout * 1000);
		}, 300);
	}

	function trackingListeners() {
		window.addEventListener('mousemove', trackingHandler);
		window.addEventListener('scroll', trackingHandler);
		window.addEventListener('keydown', trackingHandler);
	}

	function cleanUp() {
		localStorage.removeItem(STORAGE_KEY);
	}

	function cleanUpAll() {
		localStorage.removeItem(STORAGE_KEY);
		clearInterval(intervalTracker);
		window.removeEventListener('mousemove', trackingHandler);
		window.removeEventListener('scroll', trackingHandler);
		window.removeEventListener('keydown', trackingHandler);
	}

	return { cleanUpAll };
}
