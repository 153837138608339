import styled from 'styled-components';
import Icon from 'components/Icon';

export const Wrapper = styled.div`
	margin: 0 0 20px 0;
`;

export const Document = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	margin-bottom: 10px;
`;

export const StyledIcon = styled(Icon)`
	color: ${p => p.theme.palette.status.error};
	text-align: left;
`;

export const StyledButton = styled.button`
	background: none;
	border: none;
	color: ${p => p.theme.palette.primary.main};
	cursor: pointer;
	font-family: inherit;
	font-size: 1rem;
	text-align: left;
	text-decoration: underline;
	padding: 0;

	&:hover {
		text-decoration: none;
	}
`;

export const SubTitle = styled.div`
	color: ${p => p.theme.palette.common.darkGray};
	font-size: 14px;
`;

export const NoDocumentsMessage = styled.div`
	font-style: italic;
	font-weight: 300;
	text-align: center;
	margin-top: 20px;
`;
