import { Fragment } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import { Card, CardContent } from 'components/GridCardLayout';

import { RESTORE_PROFF_DATA_DEVIATION_EXCEPTION } from 'api/mutations/tenantMutations';

const ProffDataDeviationExceptionsWrapper = styled.div`
	margin-bottom: 30px;
`;

const ErrorMessage = styled.span`
	color: ${({ theme }) => theme.palette.status.error};
	font-weight: 700;
`;

const StyledCard = styled(Card)`
	max-width: 800px;
	width: 100%;
`;

function renderException(exception) {
	switch (exception.type) {
		case 'PROFF_USER_NOT_IN_COMPANY':
			return `${exception.name} har rollen ${exception.role} i Proff`;
		case 'COMPANY_USER_NOT_IN_PROFF':
			return `${exception.name} med rolle ${exception.role} mangler i Proff`;
		case 'PROFF_OWNER_NOT_IN_COMPANY':
			return `${exception.name} er rettighetshaver i Proff`;
		case 'COMPANY_OWNER_NOT_IN_PROFF':
			return `${exception.name} er ikke rettighetshaver i Proff`;
		case 'COMPANY_AUDITOR_NOT_IN_PROFF':
			return `${exception.name} er ikke registrert revisor i Proff`;
		default:
			console.warn(`Mangler type: ${exception.type}`);
			return null;
	}
}

export default function ProffDataDeviationExceptions({
	companyId,
	deviationExceptions,
}) {
	const { notify } = useSnackbar();

	const [restoreException, { loading, error }] = useMutation(
		RESTORE_PROFF_DATA_DEVIATION_EXCEPTION,
		{
			refetchQueries: ['findOneTenant'],
		}
	);

	if (!deviationExceptions || deviationExceptions.length === 0) {
		return (
			<StyledCard>
				<CardContent>Bedriften har ingen arkiverte avvik!</CardContent>
			</StyledCard>
		);
	}

	return (
		<ProffDataDeviationExceptionsWrapper>
			{error && <ErrorMessage>{error.toString()}</ErrorMessage>}

			<StyledCard>
				<CardContent>
					<List>
						{deviationExceptions.map((exception, index) => (
							<Fragment key={index}>
								<ListItem
									secondaryAction={
										<Button
											edge="end"
											size="small"
											variant="outlined"
											color="primary"
											type="button"
											disabled={loading}
											onClick={async () => {
												const {
													data: { restored },
												} = await restoreException({
													variables: {
														_id: companyId,
														exception: {
															type: exception.type,
															name: exception.name,
															role: exception?.role,
															_id: exception?._id,
														},
													},
												});

												if (restored) {
													notify(
														'Avviket ble gjenopprettet!'
													);
												}
											}}
										>
											Gjenopprett
										</Button>
									}
								>
									<ListItemText>
										{renderException(exception)}
									</ListItemText>
								</ListItem>
								<Divider component="li" />
							</Fragment>
						))}
					</List>
				</CardContent>
			</StyledCard>
		</ProffDataDeviationExceptionsWrapper>
	);
}
