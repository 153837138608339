import { useEffect, useCallback } from 'react';

/**
 * useKeyboardShortcut - A React hook to handle keyboard shortcuts that work with Ctrl on Windows and Cmd on macOS.
 *
 * @param {string} keyCombo - A string representing the key combination (e.g., 'ctrl+s').
 *                             The hook will interpret 'ctrl' as 'Cmd' on macOS and 'Ctrl' on other platforms.
 * @param {Function} callback - A function to be executed when the specified key combination is detected.
 *
 * @example
 * useKeyboardShortcut('ctrl+s', () => {
 *   console.log('Save action triggered');
 * });
 *
 * @returns {void}
 */
export default function useKeyboardShortcut(keyCombo, callback) {
	// Determine if the user is on macOS
	const isMac = navigator.userAgentData
		? navigator.userAgentData.platform === 'macOS'
		: /Mac/i.test(navigator.userAgent);

	const primaryKey = isMac ? 'metaKey' : 'ctrlKey';

	const handleKeyDown = useCallback(
		event => {
			const keys = keyCombo.toLowerCase().split('+');
			const isKeyComboPressed =
				keys.includes(event.key.toLowerCase()) &&
				(!keys.includes('ctrl') || event[primaryKey]) &&
				(!keys.includes('shift') || event.shiftKey) &&
				(!keys.includes('alt') || event.altKey);

			if (isKeyComboPressed) {
				event.preventDefault();
				callback();
			}
		},
		[keyCombo, callback, primaryKey]
	);

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleKeyDown]);
}
