import { useState, createContext, useContext } from 'react';

import useLocalStorageSetting from 'hooks/useLocalStorageSetting';

const currentDate = new Date();
const currentMonth = currentDate.getMonth();
const currentYear = currentDate.getFullYear();

// toLocaleString('nb-NO', { month: "long" }) display the month name in lowercase.
const monthLabels = {
	0: 'Januar',
	1: 'Februar',
	2: 'Mars',
	3: 'April',
	4: 'Mai',
	5: 'Juni',
	6: 'Juli',
	7: 'August',
	8: 'September',
	9: 'Oktober',
	10: 'November',
	11: 'Desember',
};

export const AppFiltersContext = createContext();

export function useAppFilters() {
	const context = useContext(AppFiltersContext);

	if (context === undefined) {
		throw new Error(
			'useApplFilters must be used within a AppFiltersProvider'
		);
	}

	return context;
}

export default function AppFiltersProvider({ children }) {
	const [search, setSearch] = useState('');
	// We dont't save the current year and month in local storage because we want to reset the filters when the user reloads the page or logs out.
	const [year, setYear] = useState({
		value: currentYear,
		label: currentYear.toString(),
	});

	const [month, setMonth] = useState({
		value: currentMonth,
		label: monthLabels[currentMonth],
	});

	const [task, setTask] = useLocalStorageSetting('task-filter');

	const [department, setDepartment] =
		useLocalStorageSetting('department-filter');

	const [accountant, setAccountant] =
		useLocalStorageSetting('accountant-filter');

	const [accountManager, setAccountManager] = useLocalStorageSetting(
		'account-manager-filter'
	);

	const [payrollManager, setPayrollManager] = useLocalStorageSetting(
		'payroll-manager-filter'
	);

	const [annualAccountsManager, setAnnualAccountsManager] =
		useLocalStorageSetting('annual-accounts-manager-filter');

	const [controller, setController] =
		useLocalStorageSetting('controller-filter');

	const [auditor, setAuditor] = useLocalStorageSetting('auditor-filter');

	const [accountantLegacy, setAccountantLegacy] = useLocalStorageSetting(
		'accountant-legacy-filter'
	);

	const [accountantRole, setAccountantRole] = useLocalStorageSetting(
		'accountant-role-filter'
	);

	const [accountantRoleLegacy, setAccountantRoleLegacy] =
		useLocalStorageSetting('accountant-role-legacy-filter');

	const [userRole, setUserRole] = useLocalStorageSetting('user-role-filter');

	const [company, setCompany] = useLocalStorageSetting('company-filter');

	const [auditingCompany, setAuditingCompany] =
		useLocalStorageSetting('auditing-company');

	const [accountType, setAccountType] = useLocalStorageSetting(
		'account-type-filter'
	);

	const [controlType, setControlType] = useLocalStorageSetting(
		'control-types-filter',
		{
			value: 'arsregnskap-skattemelding',
			label: 'Årsregnskap og skattemelding',
		}
	);

	return (
		<AppFiltersContext.Provider
			value={{
				search,
				setSearch,
				year,
				setYear,
				month,
				setMonth,
				task,
				setTask,
				department,
				setDepartment,
				accountant,
				setAccountant,
				accountManager,
				setAccountManager,
				payrollManager,
				setPayrollManager,
				annualAccountsManager,
				setAnnualAccountsManager,
				controller,
				setController,
				auditor,
				setAuditor,
				accountantLegacy,
				setAccountantLegacy,
				accountantRole,
				setAccountantRole,
				accountantRoleLegacy,
				setAccountantRoleLegacy,
				userRole,
				setUserRole,
				company,
				setCompany,
				auditingCompany,
				setAuditingCompany,
				accountType,
				setAccountType,
				controlType,
				setControlType,
			}}
		>
			{children}
		</AppFiltersContext.Provider>
	);
}
