import { gql } from '@apollo/client';

export const CREATE_FOLDER = gql`
	mutation createFolder($name: String!, $parentId: ID!, $tenantId: ID!) {
		folder: insertOneDocumentNode(
			name: $name
			type: "folder"
			parentId: $parentId
			tenantId: $tenantId
		) {
			_id
			name
			type
			parentId
			tenantId
			date
			contentType
			size
			isSigning
			isSigningComplete
			status
			isArchived
			createdBy
			updatedAt
			createdAt
		}
	}
`;

export const UPDATE_DOCUMENT_NODE = gql`
	mutation updateDocumentNode(
		$_id: ID!
		$parentId: ID
		$name: String
		$status: String
	) {
		documentNode: updateOneDocumentNode(
			_id: $_id
			parentId: $parentId
			name: $name
			status: $status
		) {
			_id
			name
			type
			parentId
			tenantId
			date
			contentType
			size
			isSigning
			isSigningComplete
			status
			isArchived
			createdBy
			updatedAt
			createdAt
		}
	}
`;

export const DELETE_FOLDER_NODE = gql`
	mutation deleteFolderNode($_id: ID!) {
		deleted: deleteOneFolderNode(_id: $_id)
	}
`;

export const DELETE_FILE_NODE = gql`
	mutation deleteFileNode($_id: ID!) {
		deleted: deleteOneFileNode(_id: $_id)
	}
`;

export const DELETE_DOCUMENT_NODE = gql`
	mutation deleteDocumentNode($_id: ID!) {
		deleted: deleteOneDocumentNode(_id: $_id)
	}
`;

export const CLEAR_RECENT_DOCUMENTS = gql`
	mutation markNewlyUploadedAsViewed($ids: [ID]!) {
		ok: markAsViewed(ids: $ids)
	}
`;

export const CREATE_ONE_DOCUMENT_NODE = gql`
	mutation insertOneDocumentNode(
		$name: String!
		$size: Int!
		$contentType: String!
		$parentId: ID
		$date: DateTime
		$tenantId: ID!
		$order: Int
		$manuallySigned: Boolean
	) {
		file: insertOneDocumentNode(
			name: $name
			size: $size
			contentType: $contentType
			parentId: $parentId
			date: $date
			tenantId: $tenantId
			type: "file"
			order: $order
			manuallySigned: $manuallySigned
		) {
			_id
			name
			size
			contentType
			parentId
			date
			uploadUrl
			status
		}
	}
`;

export const REQUEST_DOCUMENT_DOWNLOAD_URL = gql`
	mutation requestDocumentDownloadUrl($documentId: ID!) {
		item: requestDocumentDownloadUrl(documentId: $documentId) {
			url
			name
		}
	}
`;

export const REQUEST_SIGNED_DOCUMENT_DOWNLOAD_URL = gql`
	mutation requestSignedDocumentDownloadUrl($documentId: ID!) {
		item: requestSignedDocumentDownloadUrl(documentId: $documentId) {
			url
			name
		}
	}
`;

export const INSERT_ONE_DOCUMENT_NODE = gql`
	mutation insertOneDocumentNode(
		$name: String!
		$size: Int!
		$contentType: String!
		$parentId: ID
		$date: DateTime
		$tenantId: ID!
		$manuallySigned: Boolean
	) {
		file: insertOneDocumentNode(
			name: $name
			size: $size
			contentType: $contentType
			parentId: $parentId
			date: $date
			tenantId: $tenantId
			manuallySigned: $manuallySigned
			type: "file"
		) {
			_id
			name
			size
			contentType
			parentId
			date
			uploadUrl
		}
	}
`;

export const UPDATE_ONE_DOCUMENT_NODE = gql`
	mutation updateOneDocumentNode(
		$_id: ID!
		$parentId: ID
		$status: String
		$isHidden: Boolean
	) {
		file: updateOneDocumentNode(
			_id: $_id
			parentId: $parentId
			status: $status
			isHidden: $isHidden
		) {
			_id
			name
			type
			parentId
			createdByDisplayName
			tenantId
			status
		}
	}
`;

export const RENAME_ONE_DOCUMENT_NODE = gql`
	mutation renameOneDocumentNode(
		$_id: ID!
		$name: String
		$manuallySigned: Boolean
	) {
		file: renameOneDocumentNode(
			_id: $_id
			name: $name
			manuallySigned: $manuallySigned
		) {
			_id
			name
			type
			parentId
			createdByDisplayName
			tenantId
			status
		}
	}
`;

export const NOTIFY_USERS = gql`
	mutation notifyUsers(
		$documentId: ID!
		$users: [ID]
		$channels: [NotificationChannels]
	) {
		sent: notifyUsers(
			documentId: $documentId
			users: $users
			channels: $channels
		)
	}
`;

export const SHARE_DOCUMENT = gql`
	mutation shareDocument($documentId: ID!, $shareWith: [SigneeInput]!) {
		shareDocument(documentId: $documentId, shareWith: $shareWith)
	}
`;
