import { useAppFilters } from 'web/contexts/AppFiltersContext';

import Filter from 'components/filters/Filter';

const controlTypes = [
	{
		value: 'arsregnskap-skattemelding',
		label: 'Årsregnskap og skattemelding',
	},
	{ value: 'kvalitet', label: 'Kvalitet' },
];

export default function CompanyControlTypesFilter({
	disabled = false,
	size = 'medium',
}) {
	const { controlType, setControlType } = useAppFilters();

	return (
		<Filter
			name="company-controls-filter"
			label="Vis bedriftskontroll"
			value={controlType}
			options={controlTypes}
			onChange={setControlType}
			disabled={disabled}
			size={size}
			minWidth="300px"
		/>
	);
}
