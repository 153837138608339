import { useState } from 'react';
import Icon from 'components/Icon';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';

export default function ListItemMenu({ name = 'Handlinger', children }) {
	const [anchorEl, setAnchorEl] = useState(null);

	const open = event => {
		setAnchorEl(event.currentTarget);
	};

	const close = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				aria-owns={anchorEl ? `${name}-menu` : undefined}
				aria-label={name}
				aria-haspopup="true"
				onClick={open}
				style={{ padding: 0, textAlign: 'right' }}
			>
				<Icon icon="ellipsis-v" light />
			</IconButton>

			<Menu
				id={`${name}-menu`}
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={close}
			>
				{children}
			</Menu>
		</>
	);
}
