import { useState } from 'react';
import { useQuery } from '@apollo/client';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import Stack from '@mui/material/Stack';
import SendIcon from '@mui/icons-material/Send';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';

import { validateEmail, validatePhone } from 'utils/strings';

import AutocompleteField from 'components/form/AutocompleteField';

import { useWebApp } from 'web/contexts/WebContexts';

import ErrorMessage from 'components/ErrorMessage';

import Form from 'components/form/Form';
import SwitchField from 'components/form/SwitchField';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';

import { FIND_TENANT_USERS } from 'api/queries/userQueries';

export default function SigneeDialog({ signee, isOpen, submit, close }) {
	return (
		<Dialog
			open={isOpen}
			fullWidth={true}
			maxWidth="xs"
			onClose={close}
			aria-labelledby="document-create-dialog-title"
		>
			<DialogTitle id="document-create-dialog-title">
				Rediger signering
			</DialogTitle>

			{!signee ? (
				<div>Mangler data</div>
			) : (
				<SigneeForm signee={signee} submit={submit} close={close} />
			)}
		</Dialog>
	);
}

function SigneeForm({ signee, submit, close }) {
	const [isUser, setIsUser] = useState(!!signee.userId);
	const [formError, setFormError] = useState(null);

	return (
		<Form
			values={{
				isUser: !!signee.userId,
				email: signee.email,
				phone: signee.phone,
				userId: signee.userId,
			}}
			onSubmit={values => {
				setFormError(null);

				let user = { email: '', phone: '', userId: '' };

				if (isUser) {
					user.email = '';
					user.phone = '';
					user.userId = values.userId;
				} else {
					if (!values.email || !values.phone) {
						setFormError(
							'E-postadresse og telefonnummer må fylles ut'
						);
						return;
					}

					if (!validateEmail(values.email)) {
						setFormError('E-postadressen er ugyldig');
						return;
					}

					if (!validatePhone(values.phone)) {
						setFormError('Telefonnummeret er ugyldig');
						return;
					}

					user.email = values.email;
					user.phone = values.phone;
					user.userId = '';
				}

				submit(user);
			}}
		>
			<DialogContent>
				<ErrorMessage errors={formError} />

				<SwitchField
					name="isUser"
					defaultValue={isUser}
					label={
						isUser ? 'Bytt til e-post og tlf' : 'Bytt til bruker'
					}
					onChange={value => {
						setIsUser(value);
					}}
				/>

				{isUser ? (
					<UserSelectField />
				) : (
					<>
						<TextField
							name="email"
							type="email"
							label="E-postadresse"
						/>

						<TextField
							name="phone"
							type="tel"
							label="Telefonnummer"
						/>
					</>
				)}
			</DialogContent>

			<DialogActions>
				<Button onClick={close}>Avbryt</Button>

				<SubmitButton icon={<SendIcon />} variant="contained">
					Oppdater
				</SubmitButton>
			</DialogActions>
		</Form>
	);
}

function UserSelectField() {
	const { data: { tenant } = { tenant: {} } } = useWebApp();

	const {
		data: { users } = { users: [] },
		loading,
		error,
	} = useQuery(FIND_TENANT_USERS, {
		variables: { tenantId: tenant?._id },
	});

	return (
		<div>
			<ErrorMessage errors={error} />

			<AutocompleteField
				name="userId"
				label="Bruker"
				disabled={loading}
				options={
					users?.items?.map(user => {
						return {
							label: user.name,
							value: user._id,
							tooltip: [
								<Stack
									key="1"
									direction="row"
									alignItems="center"
									gap={1}
								>
									<MailIcon />
									{user.email || 'Mangler e-post'}
								</Stack>,
								<Stack
									key="2"
									direction="row"
									alignItems="center"
									gap={1}
								>
									<PhoneIcon />
									{user.phone || 'Mangler telefonnr'}
								</Stack>,
							],
						};
					}) || []
				}
			/>
		</div>
	);
}
