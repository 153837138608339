import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

import useKeyboardShortcut from 'hooks/useKeyboardShortcut';

export const UnderlineLink = styled(Link)`
	color: ${p => p.theme.palette.primary.main};
	text-decoration: underline;
	transition: all 0.5s;

	&:hover {
		color: ${p => p.theme.palette.text.primary};
	}
`;

export const StyledALink = styled.a`
	color: ${p => p.theme.palette.primary.main};
	text-decoration: underline;
	transition: all 0.5s;

	&:hover {
		color: ${p => p.theme.palette.text.primary};
	}
`;

export const StyledLink = styled(Link)`
	color: inherit;
	text-decoration: inherit;
	transition: all 0.5s;

	&:hover {
		color: ${p => p.theme.palette.primary.main};
	}
`;

export default StyledLink;

export function ShortcutLink({ to, shortcut, children }) {
	const navigate = useNavigate();

	useKeyboardShortcut(shortcut, () => {
		navigate(to);
	});

	return <Link to={to}>{children}</Link>;
}

export function EmailLink({ email }) {
	return <StyledALink href={`mailto:${email}`}>{email}</StyledALink>;
}

export function PhoneLink({ phone }) {
	return <StyledALink href={`tel:${phone}`}>{phone}</StyledALink>;
}
