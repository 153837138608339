import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import Loading from 'components/Loading';

import DesktopLayout, { NarrowContent } from 'web/components/Layout';

import { GET_ONE_COMPANY_APPLICATION } from 'api/queries/companyApplicationQueries';
import { DELETE_ONE_COMPANY_APPLICATION } from 'api/mutations/companyApplicationMutations';

const Shareholders = styled.div``;

const Section = styled.div`
	background: ${p => p.theme.palette.background.paper};
	margin-bottom: 30px;
	padding: 10px 10px 1px;
`;

const Header = styled.h3`
	margin: 0 0 10px 0;
`;

const Actions = styled.div`
	margin-top: 40px;
	display: flex;
	justify-content: flex-end;
`;

export default function CompanyApplicationSingle() {
	const navigate = useNavigate();

	const [active, setActive] = useState('');
	const { companyApplicationId } = useParams();

	const {
		data: { companyApplication } = {},
		loading,
		error,
	} = useQuery(GET_ONE_COMPANY_APPLICATION, {
		variables: {
			_id: companyApplicationId,
		},
	});

	const [deleteOneCompanyApplication, { data }] = useMutation(
		DELETE_ONE_COMPANY_APPLICATION
	);

	useEffect(() => {
		if (!data) {
			return;
		}

		navigate('/soknader/');
	}, [data]);

	if (error) {
		navigate('/404/');
	}

	if (loading || !companyApplication?._id) return <Loading />;

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					to: '/soknader/',
					label: 'Søknader',
				},
				{
					label: companyApplication?.name,
				},
			]}
		>
			<NarrowContent>
				<Header>Søker</Header>

				<Section>
					<EntityDisplay
						show
						entity={companyApplication.contact}
						active={active}
						isPerson={true}
						setActive={setActive}
						groupId="contact"
					/>
				</Section>

				<Header>Selskapets navn</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						label="Navn"
						value={companyApplication?.companyName}
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						label="Stiftelsesdato"
						value={companyApplication?.companyFoundationDate}
					/>
				</Section>

				<Header>Forretningsadresse</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.companyAddress}
						label="Adresse"
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.companyPostalCode}
						label="Postnr"
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.companyCounty}
						label="Sted"
					/>
				</Section>

				<Header>Vedtektsfestet formål</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.companyPurpose}
						label="Formål"
					/>
				</Section>

				<Header>Aksjekapital i selskapet</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.stockCapital}
						label="Aksjekapitalen"
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.stockAmount}
						label="Antall aksjer"
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.stockPremium}
						label="Overkurs"
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.stockItemDeposit}
						label="Tingsinnskudd"
					/>
				</Section>

				<Header>Aksjonærer</Header>

				<Section>
					<Shareholders>
						{companyApplication?.stockholders?.map(
							(stockholder, index) => (
								<EntityDisplay
									header={`Aksjonær ${index + 1}`}
									show
									groupId={stockholder._id}
									key={stockholder._id}
									entity={stockholder}
									active={active}
									setActive={setActive}
									displayShares
									displayIdentifier
								/>
							)
						)}
					</Shareholders>
				</Section>

				{/* Den nye versjonen */}

				<Header>Daglig leder, kontaktperson</Header>

				<Section>
					<EntityDisplay
						show
						entity={companyApplication.primaryContact}
						active={active}
						setActive={setActive}
						groupId="primaryContact"
						isPerson={true}
						header={companyApplication?.primaryContact?.role}
					/>
				</Section>

				<Header>Valg av styre og revisor</Header>

				<Section>
					<Shareholders>
						{companyApplication?.boardMembers?.map(member => (
							<EntityDisplay
								header={member.role}
								show
								groupId={member._id}
								key={member._id}
								entity={member}
								active={active}
								setActive={setActive}
								isPerson={true}
								displayShares
								displayIdentifier
							/>
						))}
					</Shareholders>
				</Section>

				<Header>Regnskapskontor</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.accountingOffice}
						label="Regnskpskontor"
					/>
				</Section>

				<Header>Revisor</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.auditor}
						label="Revisor"
					/>
				</Section>

				<Header>Signatur</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.signatories}
						label="Selskapets firma tegnes av"
					/>
				</Section>

				<Header>Prokura</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.procura}
						label="Procura"
					/>
				</Section>

				<Header>Vedtekter</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.bylaws}
						label="Vedtekter"
					/>
				</Section>

				<Header>Stiftelsesutgifter</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.registrationFee}
						label="Brønnøysundregistrerne"
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.auditorFee}
						label="Admento AS"
					/>
				</Section>

				<Actions>
					<Button
						variant="contained"
						size="large"
						startIcon={<DeleteIcon />}
						onClick={async () => {
							await deleteOneCompanyApplication({
								variables: {
									_id: companyApplicationId,
								},
								refetchQueries: ['paginateCompanyApplications'],
							});
						}}
					>
						Arkiver søknad
					</Button>
				</Actions>
			</NarrowContent>
		</DesktopLayout>
	);
}

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
`;

const Label = styled.div`
	font-weight: 700;
	font-style: italic;
	padding-right: 15px;
`;

const Value = styled.div`
	margin: 0 10px 0 auto;
`;

function InfoRow({ label, value, active, setActive, groupId }) {
	const { notify } = useSnackbar();

	const activeRow = groupId ? active === groupId + label : active === label;

	return (
		<Row>
			<Label>{label}</Label>

			<Value>{value}</Value>

			<Tooltip title="Kopier tekst" placement="top">
				<IconButton
					aria-label="Kopier verdi"
					size="small"
					color={activeRow ? 'success' : 'default'}
					disabled={!value || value === ''}
					onClick={() => {
						setActive(groupId ? groupId + label : label);

						navigator.clipboard.writeText(value);

						notify('Tekst kopiert til utklippstavlen!');
					}}
				>
					<ContentCopyIcon fontSize="inherit" />
				</IconButton>
			</Tooltip>
		</Row>
	);
}

const EntityHeader = styled.div`
	font-weight: 700;
	margin-bottom: 10px;
`;

function EntityDisplay({
	show,
	entity,
	displayShares,
	displayIdentifier,
	displayRole,
	active,
	setActive,
	groupId,
	header,
}) {
	const [visible, setVisible] = useState(show || false);

	return (
		<div>
			{header && (
				<Row>
					<EntityHeader>{header}</EntityHeader>

					<Tooltip title={visible ? 'Skjul' : 'Vis'} placement="top">
						<IconButton
							aria-label={visible ? 'Skjul' : 'Vis'}
							size="small"
							onClick={() => setVisible(!visible)}
						>
							{visible ? (
								<ExpandLessIcon size="small" />
							) : (
								<ExpandMoreIcon size="small" />
							)}
						</IconButton>
					</Tooltip>
				</Row>
			)}

			<InfoRow
				active={active}
				setActive={setActive}
				value={entity?.name}
				label="Navn"
				groupId={groupId}
			/>

			{visible && (
				<>
					{displayIdentifier && (
						<InfoRow
							active={active}
							setActive={setActive}
							value={entity?.identifier}
							label="Orgnr/Personnr"
							groupId={groupId}
						/>
					)}

					{displayRole && (
						<InfoRow
							active={active}
							setActive={setActive}
							value={entity?.address}
							label="Adresse"
							groupId={groupId}
						/>
					)}

					<InfoRow
						active={active}
						setActive={setActive}
						value={entity?.address}
						label="Adresse"
						groupId={groupId}
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={entity?.postalCode}
						label="Postnummer"
						groupId={groupId}
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={entity?.county}
						label="Sted"
						groupId={groupId}
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={entity?.phone}
						label="Telefon"
						groupId={groupId}
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={entity?.email}
						label="Epost"
						groupId={groupId}
					/>

					{displayShares && (
						<InfoRow
							active={active}
							setActive={setActive}
							value={entity?.shares}
							label="Aksjeandel"
							groupId={groupId}
						/>
					)}
				</>
			)}
		</div>
	);
}
