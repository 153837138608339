import { useMutation } from '@apollo/client';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import {
	Dialog,
	DialogTitle,
	DialogCloseButton,
	DialogActions,
	DialogContent,
	Button,
} from 'components/dialogs/DialogBox';

import { ADD_ANNUAL_FINANCIAL_STATUS } from 'api/mutations/tenantMutations';

const thisYear = new Date().getFullYear();
const previousYear = thisYear - 1;

export default function AnnualSettlementStatusDialog({
	companyId,
	isOpen,
	setIsOpen,
}) {
	const { notify } = useSnackbar();

	const [addAnnualFinancialSatus] = useMutation(ADD_ANNUAL_FINANCIAL_STATUS, {
		refetchQueries: ['paginateTenants'],
	});

	function handleClose() {
		setIsOpen(false);
	}

	async function handleSetReadyForControl() {
		try {
			const { data } = await addAnnualFinancialSatus({
				variables: {
					_id: companyId,
					year: previousYear,
				},
			});

			if (data?.added) {
				notify(`Regnskap ${previousYear} er nå klart for gjennomgang`);
				setIsOpen(false);
				return;
			}

			throw new Error('Kunne ikke sette regnskap klart for gjennomgang');
		} catch (err) {
			console.error(err);
			notify(
				`Noe gikk galt. Kunne ikke sette regnskap ${previousYear} klart for gjennomgang`,
				'error'
			);
			setIsOpen(false);
		}
	}

	async function handleSetNotRelevant() {
		try {
			const { data } = await addAnnualFinancialSatus({
				variables: {
					_id: companyId,
					year: previousYear,
					type: 'not-relevant',
				},
			});

			if (data?.added) {
				notify(`Regnskap ${previousYear} er nå satt som ikke aktuelt`);
				setIsOpen(false);
				return;
			}

			throw new Error('Kunne ikke sette regnskap som ikke aktuelt');
		} catch (err) {
			console.error(err);
			notify(
				`Noe gikk galt. Kunne ikke sette regnskap ${previousYear} som ikke aktuelt`,
				'error'
			);
			setIsOpen(false);
		}
	}

	return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			fullWidth={true}
			maxWidth="sm"
		>
			<DialogTitle id="alert-dialog-slide-title">
				Årsregnskap {previousYear}
			</DialogTitle>

			<DialogCloseButton onClick={handleClose} />

			<DialogContent id="alert-dialog-slide-description">
				Ønsker du å sette årsregnskapet for {previousYear} klar til
				kontroll?
			</DialogContent>

			<DialogActions>
				<Button onClick={handleClose}>Avbryt</Button>

				<Button onClick={handleSetNotRelevant} variant="outlined">
					Merk som ikke relevant
				</Button>

				<Button
					onClick={handleSetReadyForControl}
					variant="contained"
					autoFocus
				>
					Sett klar til kontroll
				</Button>
			</DialogActions>
		</Dialog>
	);
}
