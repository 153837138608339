import styled from 'styled-components';

import StatisticsDialogProvider from 'web/contexts/StatisticsDialogContext';

import DesktopLayout, { ArchiveHeader } from 'web/components/Layout';
import ContentWithDrawer from 'web/components/ContentWithDrawer';

import AppFiltersActive from 'components/filters/AppFiltersActive';

import TaskWeekCalendarWidget from 'web/components/widgets/TaskWeekCalendarWidget';
import MySignatureRequestsWidget from 'web/components/widgets/MySignatureRequestsWidget';
import MyUnsignedDocumentsWidget from 'web/components/widgets/MyUnsignedDocumentsWidget';
import TodosWidget from 'web/components/widgets/TodosWidget';
import GovernmentDeadlinesWidget from 'web/components/widgets/GovernmentDeadlinesWidget';
import CompaniesArchiveSidebar from 'web/components/companiesArchive/CompaniesArchiveSidebar';

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 30px;
	margin-bottom: 30px;
`;

const Column = styled.div`
	grid-column: span ${({ span }) => span};
`;

const displayFilters = [
	'task',
	'accountantLegacy',
	'accountantRole',
	'company',
];

export default function AccountantHome() {
	return (
		<DesktopLayout
			title="Hjem"
			breadcrumbs={[
				{
					to: '/',
					label: 'Hjem',
				},
			]}
		>
			<ContentWithDrawer
				drawer={
					<CompaniesArchiveSidebar displayFilters={displayFilters} />
				}
			>
				<div>
					<StatisticsDialogProvider>
						<ArchiveHeader>
							<AppFiltersActive displayFilters={displayFilters} />
						</ArchiveHeader>

						<Grid>
							<Column span={12}>
								<TaskWeekCalendarWidget />
							</Column>

							<Column span={3}>
								<MyUnsignedDocumentsWidget />
							</Column>

							<Column span={3}>
								<MySignatureRequestsWidget />
							</Column>

							<Column span={3}>
								<TodosWidget />
							</Column>

							<Column span={3}>
								<GovernmentDeadlinesWidget />
							</Column>
						</Grid>
					</StatisticsDialogProvider>
				</div>
			</ContentWithDrawer>
		</DesktopLayout>
	);
}
